import { message } from 'antd';
import fetch from 'auth/FetchInterceptor';

const userService = {};
const endpoint = '/users/';

userService.getUsers = async function () {
  try {
    const response = await fetch({
      url: endpoint,
      method: 'get'
    });
    return response;
  } catch (error) {
    console.error('Failed to fetch users:', error);
    throw error;
  }
}

userService.setUser = async function (data) {
  try {
    const response = await fetch({
      url: `${endpoint}`,
      method: 'post',
      data: data
    });
    message.success('User created successfully!', 2);
    return response;
  } catch (error) {
    message.error(`Error creating user: ${error}`, 2);
    throw error;
  }
}

userService.updateUser = async function (data) {
  try {
    const response = await fetch({
      url: `${endpoint}${data.id}`,  // Assuming the user ID is included in the data object
      method: 'put',
      data: data
    });
    message.success('User updated successfully!', 2);
    return response;
  } catch (error) {
    message.error(`Error updating user: ${error}`, 2);
    throw error;
  }
}

userService.deleteUser = async function (id) {
  try {
    const response = await fetch({
      url: `${endpoint}${id}`,
      method: 'delete'
    });
    message.success('User deleted successfully!', 2);
    return response;
  } catch (error) {
    message.error(`Error deleting user: ${error}`, 2);
    throw error;
  }
}

userService.getUserByEmailAddress = async function (email) {
  try {
    const response = await fetch({
      url: `${endpoint}email/${email}`,
      method: 'get'
    });
    return response;
  } catch (error) {
    console.error("Failed to fetch user by email:", error);
    throw error; // Re-throw the error after logging or handling it
  }
}

userService.fetchLoggedUser = async function (user) {
  try {
    const response = await userService.getUserByEmailAddress(user.email);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return await createAndHandleNewUser(user);
    }
    console.error("Error fetching logged user:", error);
    throw error;
  }
}

async function createAndHandleNewUser(user) {
  const newUser = {
    email: user.email,
    name: user.displayName ? user.displayName.split(' ')[0] : '',
    surname: user.displayName ? user.displayName.split(' ')[1] : '',
    is_active: true,
    firebase_id: user.uid
  };
  try {
    const createdUser = await userService.setUser(newUser);
    message.success('User created successfully!', 2);
    return createdUser;
  } catch (error) {
    message.error(`Error creating user: ${error}`, 2);
    throw error;
  }
}

export default userService;
