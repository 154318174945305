import { createSlice } from '@reduxjs/toolkit';

export const bankSlice = createSlice({
  name: 'bank',
  initialState: {
    defaultBankAccount: null,
  },
  reducers: {
    setDefaultBankAccount: (state, action) => {
      return {
        ...state,
        defaultBankAccount: action.payload
      };
    },
  },
});

export const { setDefaultBankAccount } = bankSlice.actions;

export default bankSlice.reducer;
