const FirebaseConfig = {
  apiKey: "AIzaSyAkwy6dPPM1gIaa2ZqZmAniR3n6tt14rUU",
  authDomain: "jlblanco-blancolabs.firebaseapp.com",
  databaseURL: "https://jlblanco-blancolabs-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "jlblanco-blancolabs",
  storageBucket: "jlblanco-blancolabs.appspot.com",
  messagingSenderId: "966816575148",
  appId: "1:966816575148:web:73a9b3ffab223c9de07909",
  measurementId: "G-32KPR52CZ6"
};

export default FirebaseConfig
