import {
  auth,
  signInWithEmailAndPassword,
  signOut,
  googleAuthProvider,
  facebookAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword
} from 'auth/FirebaseAuth';
import UserService from 'services/bcontrolv7api/UserService';
import { message } from 'antd';

const FirebaseService = {}

FirebaseService.signInEmailRequest = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err);
}

FirebaseService.signOutRequest = async () =>
	await signOut(auth).then(user => user).catch(err => err);


FirebaseService.signInGoogleRequest = async () => {
  try {
    let userCredential = await signInWithPopup(auth, googleAuthProvider);
    // Fetch user from the database based on email
    const user = await UserService.fetchLoggedUser(userCredential.user);

    // Let's append user id to the userCredential object
    userCredential.user.userId = user.id;

    return userCredential;
  } catch (error) {
    console.log('Error signing in with Google', error);
    message.error({ content: 'Error signing in with Google', duration: 2 });
  }
};

FirebaseService.signInFacebookRequest = async () =>
  await signInWithPopup(auth, facebookAuthProvider).then(user => user).catch(err => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
	await createUserWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err);

export default FirebaseService
